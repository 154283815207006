<template>
  <section class="card-section">
    <div class="card-container">
      <div v-for="(card, index) in cards" :key="index" class="card-wrap" @mousemove="(e) => handleMouseMove(e, index)"
        @mouseleave="(e) => handleMouseLeave(index)" :ref="'card' + index">
        <div class="card" :style="cardStyle(index)">
          <div class="image-container">
            <img :src="card.image" alt="e" class="card-image" :style="cardBgTransform(index)" />
          </div>
          <div class="card-info">
            <h1>{{ card.title }}</h1>
            <p>{{ card.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CardComponent",
  data() {
    return {
      cards: [
        {
          title: "SUNAD",
          description: "Optimización de módulos clave para empresas dinámicas.",
          image: "https://static.orbita.com.co/public/general/images/sunat_icon.png",
        },
        {
          title: "COLSUBSIDIO",
          description: "Sistemas inteligentes para la gestión de vivienda y registro.",
          image: "https://static.orbita.com.co/public/general/images/colsubsidio_icon.png",
        },
        {
          title: "BRANCHOS",
          description: "Soluciones escalables y flexibles para entornos empresariales.",
          image: "https://static.orbita.com.co/public/general/images/branchos_icon.png",
        },
        {
          title: "GATACA",
          description: "Innovación constante con módulos frescos cada seis meses.",
          image: "https://static.orbita.com.co/public/general/images/gattaca_icon.png",
        },
      ],
      mousePositions: [],
    };
  },
  methods: {
    initializeMousePositions() {
      this.mousePositions = this.cards.map(() => ({
        mouseX: 0,
        mouseY: 0,
        width: 0,
        height: 0,
      }));
    },
    cardStyle(index) {
      const maxRotation = 15;
      const { mouseX, mouseY, width, height } = this.mousePositions[index] || {
        mouseX: 0,
        mouseY: 0,
        width: 1,
        height: 1,
      };
      const mousePX = mouseX / width;
      const mousePY = mouseY / height;
      const rX = Math.max(-maxRotation, Math.min(mousePX * -30, maxRotation));
      const rY = Math.max(-maxRotation, Math.min(mousePY * 30, maxRotation));
      return {
        transform: `rotateY(${rX}deg) rotateX(${rY}deg)`,
      };
    },
    handleMouseMove(e, index) {
      const cardElement = this.$refs['card' + index][0];
      if (cardElement) {
        const rect = cardElement.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;
        this.mousePositions[index] = {
          mouseX,
          mouseY,
          width: rect.width,
          height: rect.height,
        };
      }
    },
    handleMouseLeave(index) {
      if (this.mousePositions[index]) {
        this.mousePositions[index].mouseX = 0;
        this.mousePositions[index].mouseY = 0;
      }
    },
    cardBgTransform(index) {
      const { mouseX, mouseY, width, height } = this.mousePositions[index] || {
        mouseX: 0,
        mouseY: 0,
        width: 1,
        height: 1,
      };
      const mousePX = mouseX / width;
      const mousePY = mouseY / height;
      const tX = mousePX * -40;
      const tY = mousePY * -40;
      return {
        transform: `translateX(${tX}px) translateY(${tY}px)`,
      };
    },
  },
  mounted() {
    this.initializeMousePositions();
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.card-image {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.card-section {
  position: relative;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.card-wrap {
  margin: 10px;
  transform: perspective(800px);
  cursor: pointer;
}

.card {
  position: relative;
  width: 240px;
  height: 320px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.5);
  transition: 0.2s ease;
}

.card-info {
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card-info h1 {
  font-size: 1.5em;
  margin: 0;
}

.card-info p {
  margin-top: 10px;
  opacity: 0;
  text-shadow: rgba(0, 0, 0, 1) 0 2px 3px;
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.card-wrap:hover .card-info {
  transform: translateY(0);
}

.card-wrap:hover .card-info p {
  opacity: 1;
}
</style>
